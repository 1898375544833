import * as Yup from "yup"

const yearNow = new Date(Date.now()).getFullYear()

export const deliveryDetailsValidationSchema = Yup.object().shape({
  ifMedicineNotAvailable: Yup.string().required("This field is required"),
  firstName: Yup.string()
    .matches(
      /(?=.*[a-zA-Z])/,
      "Your name must contain at least one valid character."
    )
    .matches(
      /^[a-zA-Z,.' ]*$/,
      "Your name must not contain an invalid special character."
    )
    .required("This field is required"),
  lastName: Yup.string()
    .matches(
      /(?=.*[a-zA-Z])/,
      "Your name must contain at least one valid character."
    )
    .matches(
      /^[a-zA-Z,.' ]*$/,
      "Your name must not contain an invalid special character."
    )
    .required("This field is required"),
  mobileNumber: Yup.string()
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .max(11, "Please input a valid mobile number in this format: 09991234567")
    .matches(
      /^09[0-9]{9}$/,
      "Please input a valid mobile number in this format: 09991234567"
    )
    .required("This field is required"),
  emailAddress: Yup.string()
    .email("Please input a valid email")
    .required("This field is required"),
  birthday: Yup.object({
    month: Yup.string().required("This field is required"),
    date: Yup.string().required("This field is required"),
    year: Yup.number()
      .min(1900, "Please input a valid year")
      .max(yearNow, "Please input a valid year")
      .required("This field is required"),
  }),
  deliveryAddress: Yup.object().shape({
    streetAddress: Yup.string().required("This field is required"),
    city: Yup.string().required("This field is required").nullable(),
    barangay: Yup.string().required("This field is required").nullable(),
    province: Yup.string().required("This field is required"),
    addressType: Yup.string().required("This field is required"),
  }),
})

export const deliveryDetailsSCPWDValidationSchema = Yup.object().shape({
  ifMedicineNotAvailable: Yup.string().required("This field is required"),
  firstName: Yup.string()
    .matches(
      /(?=.*[a-zA-Z])/,
      "Your name must contain at least one valid character."
    )
    .matches(
      /^[a-zA-Z,.' ]*$/,
      "Your name must not contain an invalid special character."
    )
    .required("This field is required"),
  lastName: Yup.string()
    .matches(
      /(?=.*[a-zA-Z])/,
      "Your name must contain at least one valid character."
    )
    .matches(
      /^[a-zA-Z,.' ]*$/,
      "Your name must not contain an invalid special character."
    )
    .required("This field is required"),
  mobileNumber: Yup.string()
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .max(11, "Please input a valid mobile number in this format: 09991234567")
    .matches(
      /^09[0-9]{9}$/,
      "Please input a valid mobile number in this format: 09991234567"
    )
    .required("This field is required"),
  emailAddress: Yup.string()
    .email("Please input a valid email")
    .required("This field is required"),
  birthday: Yup.object().shape({
    month: Yup.string().required("This field is required"),
    date: Yup.string().required("This field is required"),
    year: Yup.number()
      .min(1900, "Please input a valid year")
      .max(yearNow, "Please input a valid year")
      .required("This field is required"),
  }),
  scpwdIDNumber: Yup.string()
    .matches(/^[0-9]+$/, "Please input a valid SCPWD ID Number.")
    .required("This field is required"),
  scpwdId: Yup.object().shape({
    front: Yup.object().shape({
      path: Yup.string().required("Please upload the necessary file"),
    }),
    back: Yup.object().shape({
      path: Yup.string().required("Please upload the necessary file"),
    }),
  }),
  deliveryAddress: Yup.object().shape({
    streetAddress: Yup.string().required("This field is required"),
    city: Yup.string().required("This field is required").nullable(),
    barangay: Yup.string().required("This field is required").nullable(),
    province: Yup.string().required("This field is required"),
    addressType: Yup.string().required("This field is required"),
  }),
})

export const paymentDetailsValidationSchema = Yup.object().shape({
  changeFor: Yup.number().when("paymentOption", {
    is: (paymentOption) => paymentOption === "Cash on Delivery",
    then: Yup.number()
      .required("This field is required")
      .min(1, "This field is required"),
  }),
  selectedBank: Yup.string().when("paymentOption", {
    is: (paymentOption) =>
      paymentOption === "Bank Transfer/Deposit - UnionBank",
    then: Yup.string().required("This field is required"),
  }),
})

export const healthSurveyValidationSchema = Yup.object().shape({})

export const deliveryDetailsInitialValues = {
  firstName: "",
  lastName: "",
  employeeNumber: "",
  mobileNumber: "",
  emailAddress: "",
  deliveryAddress: {
    streetAddress: "",
    city: "",
    province: "",
    notes: "",
  },
}

export const paymentDetailsInitialValues = {
  paymentOption: "Cash on Delivery",
  changeFor: "",
}
