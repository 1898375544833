import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import styles from "../utils/epharmacy.module.scss"
import paymentOptions from "../utils/paymentMethods"
import { generateFollowUp } from "../services/paymentOptions"

const PaymentMethodOptions = ({
  values,
  setFieldValue,
  isNationwide,
  cartTotal,
}) => {
  const data = useStaticQuery(graphql`
    {
      paymentCod: file(
        relativePath: { eq: "payment-details/payment_cod.png" }
      ) {
        childImageSharp {
          fixed(width: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      paymentBank: file(
        relativePath: { eq: "payment-details/payment_bank-transfer.png" }
      ) {
        childImageSharp {
          fixed(width: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const handlePaymentValidation = (paymentOption) => {
    switch (paymentOption) {
      case "Cash on Delivery":
        return parseFloat(values.changeFor) < parseFloat(cartTotal)
      default:
        return
    }
  }

  return (
    <div className={classNames(styles["paymentDetails__paymentOptions"])}>
      {paymentOptions.map((paymentOption) => {
        if (isNationwide && !paymentOption.isNationwide) return null
        return (
          <Fragment>
            <div
              className={classNames(
                "mx-1",
                styles[
                  `paymentDetails__paymentOption${
                    values.paymentOption === paymentOption.label ? "Active" : ""
                  }`
                ]
              )}
              onClick={() => {
                setFieldValue("paymentOption", paymentOption.label)
                setFieldValue("changeFor", "")
              }}
            >
              <div
                className={classNames(styles["paymentDetails__radioButton"])}
              >
                {values.paymentOption === paymentOption.label && (
                  <div
                    className={classNames(
                      styles["paymentDetails__radioButtonChecked"]
                    )}
                  ></div>
                )}
              </div>
              <Img
                className="ml-1"
                fixed={data[paymentOption.image]?.childImageSharp?.fixed}
              />
              <p className="is-size-6 ml-1">{paymentOption.label}</p>
            </div>

            {values.paymentOption === paymentOption.label &&
              paymentOption?.followUps?.map((followUp) => (
                <div
                  className={classNames(
                    "notification is-light ml-1",
                    styles["paymentDetails__form"]
                  )}
                >
                  {generateFollowUp({
                    values,
                    followUp,
                    cartTotal,
                    selectedPaymentOption: values.paymentOption,
                  })}
                </div>
              ))}
            {paymentOption.errorMessage &&
              handlePaymentValidation(paymentOption.label) &&
              values.changeFor > 0 && (
                <div
                  className={classNames(
                    "notification ml-1 is-warning",
                    styles["paymentDetails__form"]
                  )}
                >
                  {paymentOption.errorMessage}
                </div>
              )}
          </Fragment>
        )
      })}
    </div>
  )
}

export default PaymentMethodOptions
